@import "../../styles/global.scss";

header {
    margin: 0;
    background: white;
}
h2 {
    margin: 0;
    font-size: 1em;
}

h2 a,
h2 a.active {
    text-decoration: none;
}

a {
    padding: 1em 0.5em;
    color: black;
    border-bottom: 4px solid transparent;
    text-decoration: none;
}
nav a.active {
    text-decoration: none;
    border-bottom-color: green;
}
.social-links,
.social-links a {
    display: flex;
}
@media (max-width: 720px) {
    .social-links {
        display: none;
    }
}

.hdr-wrapper {
    display: flex;
    align-items: baseline;
    padding: 20px 40px;
    justify-content: space-between;

    a {
        padding: 0;
    }
}

.internal-links {
    display: flex;
    margin: 0;
    a {
        padding: 0 5px;
    }
}

bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}

.siren-logo {
    width: 50px;
    border-radius: 0;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #72778d;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #50a3a2;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: white;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    display: flex;
    flex-direction: column;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

/* ============= additional ================ */
.menu-item {
    font-size: 20px;
    color: black;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
}

.menu-item:hover {
    color: #50a3a2;
}

.memu-title {
    color: black;
    font-size: 40px;
    margin: 10px 0 50px 0;
    text-align: center;
    border-bottom: 3px solid #808080;
    /* text-decoration: underline;
    text-underline-offset: 0.5em; */
}

.logout {
    margin-top: auto;
    border-top: 3px solid #808080;
    padding-top: 15px;
}
