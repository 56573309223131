@import '../../styles/global.scss';

.creds-one{
    display: flex;
    flex-direction: column;
    padding: 40px 24px;
    gap: 24px;
    h2{
        padding: 0;    
        margin: 0;
        min-width: 200px;
        line-height: 100%;
    }
    p{
        padding: 0;
        margin: 0;
        line-height: 100%;
    }
    a{
        margin: 0;
        line-height: 100%;
        padding: 0;
    }
    .cred{
        display: flex;
    }
}